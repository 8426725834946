import React, { useState, useEffect } from 'react';
import styles from './BottomBanner.module.css';
import { generateRegisterButtonStyle } from './util/theme';
import moment from 'moment';
import { getCheckoutLink } from '../../lib/utils';
import { useRouter } from 'next/router';

const formatDateTime = (dateTimeStr) => {
    return moment(dateTimeStr, 'DD/MM/YYYY HH:mm:ss').format("Do MMMM, h:mm A");
};

const BottomBanner = ({ pagedata, data, theme, slug }) => {
    const [isVisible, setIsVisible] = useState(false);

    const router = useRouter();
    const navigateTocheckout = () => {
        let checkoutLink = getCheckoutLink();
        if (!checkoutLink) {
            checkoutLink = "/event-checkout/" + slug;
        }
        router.push(checkoutLink);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.innerWidth < 9800) {
                setIsVisible(true);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    if (!isVisible) {
        return null;
    }

    return (
        <div className={`${styles['banner-container']} d-flex`}
            style={theme.style === 'light' ? { backgroundColor: '#ffffff90' } : {}}>
            <div className="d-flex align-items-center flex-grow-1">
                {/* {(data.showPriceNDateOnBanner ?? true) && (
        <div className={styles['price-container']}>
          <h3 className={styles['price-heading']}
          style={theme.style === 'light' ? { color: '#000' } : {}}>
            {data.salePrice ? `₹${data.salePrice}` : 'FREE'} {data.originalPrice > 0 &&  <span className={styles['original-price']}
            style={theme.style === 'light' ? { color: '#333' } : {}}>₹{data.originalPrice}</span>} 
          </h3>
          <p className={styles['register-deadline']}
          style={theme.style === 'light' ? { color: '#111' } : {}}><strong>{formatDateTime(data.registerTill)}</strong></p>
        </div> )} */}
                {
                    (data.showPriceOnBanner ?? true) ? (
                        <div className={styles['price-container']}>
                            <h3 className={styles['price-heading']}
                                style={theme.style === 'light' ? { color: '#000' } : {}}>
                                {data.salePrice ? `₹${data.salePrice}` : 'FREE'}
                                {data.originalPrice > 0 && (
                                    <span className={styles['original-price']}
                                        style={theme.style === 'light' ? { color: '#333' } : {}}>
                                        ₹{data.originalPrice}
                                    </span>
                                )}
                            </h3>
                            {(data.showTagOnBanner ?? false) ? (
                            <p className={styles['register-deadline']}
                                style={theme.style === 'light' ? { color: '#111' } : {}}>
                                <strong>{data.tagBottomBanner}</strong>
                            </p> ) : (
                                <p className={styles['register-deadline']}
                                style={theme.style === 'light' ? { color: '#111' } : {}}>
                                <strong>{formatDateTime(data.registerTill)}</strong>
                            </p>
                            )}
                        </div>
                    ) : null
                    // (data.showPriceNTagOnBanner ?? false) ? (
                    //     <div className={styles['price-container']}>
                    //         <h3 className={styles['price-heading']}
                    //             style={theme.style === 'light' ? { color: '#000' } : {}}>
                    //             {data.salePrice ? `₹${data.salePrice}` : 'FREE'}
                    //             {data.originalPrice > 0 && (
                    //                 <span className={styles['original-price']}
                    //                     style={theme.style === 'light' ? { color: '#333' } : {}}>
                    //                     ₹{data.originalPrice}
                    //                 </span>
                    //             )}
                    //         </h3>
                    //         <p className={styles['register-deadline']}
                    //             style={theme.style === 'light' ? { color: '#111' } : {}}>
                    //             <strong>{data.tagBottomBanner}</strong>
                    //         </p>
                    //     </div>
                    // ) : null
                }

            </div>
            <div className={styles['seats-container']}>
                {(data.showSeatsLeftOnBanner ?? true) && (
                    <div className={styles['seats-info']}>
                        <div><span className={styles['seats-left']}>{data.seatsLeft}</span> seats left</div>
                    </div>)}
                <a onClick={navigateTocheckout} href='#'
                    className={`${styles['register-button']} linkedin_btn w-inline-block`}
                    style={generateRegisterButtonStyle(theme.gradient1, theme.gradient2, theme.textColorButton)}
                >
                    <div>{data.bottomButtonText}</div>
                </a>
            </div>
        </div>
    );
};

export default BottomBanner;
